<template>
  <div class="s-table">
    <i class="null">序号</i>
    <div :ref="refHead" class="head" id="head">
      <table>
        <tr>
          <td>^0^</td>
          <td v-for="i in 30">^{{ i }}^</td>
        </tr>
      </table>
    </div>
    <div :ref="refLeft" class="left" id="left">
      <table>
        <tr v-for="i in 30">
          <td>${{ i }}$</td>
        </tr>
      </table>
    </div>
    <div :ref="refBody" class="body" id="body">
      <div class="body-con cl">
        <table v-for="i in 30">
          <tr v-for="j in 30">
            <td>{{ i }} - {{ j }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, nextTick, onUnmounted } from "vue";
// import { throttle } from "@/utils";

export default {
  name: "su-Splitter",
  components: {},
  props: {},
  setup(props, context) {
    const state = reactive({
      domBody: "",
      domHead: "",
      domLeft: "",
    });

    const refBody = (el) => (state.domBody = el);
    const refHead = (el) => (state.domHead = el);
    const refLeft = (el) => (state.domLeft = el);

    const handleAddEvent = () => {
      state.domBody.addEventListener("scroll", () => {
        state.domLeft.scrollTop = state.domBody.scrollTop;
        state.domHead.scrollLeft = state.domBody.scrollLeft;
      });
      state.domHead.addEventListener("scroll", () => {
        state.domBody.scrollLeft = state.domHead.scrollLeft;
      });
      state.domLeft.addEventListener("scroll", () => {
        state.domBody.scrollTop = state.domLeft.scrollTop;
      });
    };

    const handleRemoveEvent = () => {
      state.domBody.removeEventListener("scroll");
      state.domHead.removeEventListener("scroll");
      state.domLeft.removeEventListener("scroll");
    };

    onMounted(() => {
      handleAddEvent();
    });

    onUnmounted(() => {
      handleRemoveEvent();
    });

    return {
      ...toRefs(state),
      refBody,
      refHead,
      refLeft,
      handleAddEvent,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>