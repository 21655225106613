<template>
  <div class="con">
    <Table></Table>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, nextTick, onUnmounted } from "vue";
import Table from "@c/Table";

export default {
  name: "su-temp-table",
  components: {
    Table,
  },
  props: {},
  setup(props, context) {
    const state = reactive({
      tmp: "",
    });

    onMounted(() => {});

    onUnmounted(() => {});

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
